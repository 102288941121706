export const skillsContent = {
  id: "skills",
  title: "Skills",
  content: {
    type: "SKILLS",
    details: [
      "React",
      "Redux",
      "Recoil",
      "Context API",
      "Styled Components",
      "JavaScript",
      "TypeScript",
      "HTML",
      "CSS",
      "LESS",
      "SASS",
      "Node",
      "Express",
      "Knex",
      "SQLite3",
      "PostgreSQL",
      "RESTful APIs",
      "Relational DBs",
      "Git",
      "GitHub",
      "Deployment",
      "CI/CD",
      "AWS Amplify",
      "React Testing Library",
      "Jest",
      "Cypress.io",
      "Python",
      "Zoom",
      "Slack",
      "Trello",
      "Agile Workflow",
      "Ant Design Components",
      "React Beautiful DnD",
      "GIMP",
    ],
  },
};
